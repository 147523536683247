body {
  margin: 0 0 100px;
  padding-bottom: 25px;
  background-color: white;
  font-family: "Inter", sans-serif;
  scroll-behavior: smooth;
}
html {
  position: relative;
  min-height: 100%;
}
