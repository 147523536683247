    .App {
        text-align: center;
    }
    
    h1 {
        font-size: 48px;
        margin-top: 50px;
    }
    
    .timer {
        font-size: 24px;
        margin-bottom: 20px;
    }
    
    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 50px;
    }
    
    .cell {
        width: 50px;
        height: 50px;
        margin: 2px;
        border: 1px solid black;
        cursor: pointer;
    }
    
    .cell.selected {
        background-color: orange;
    }
    
    .circle {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: blue;
    }
    
    .square {
        width: 100px;
    }