.header {
    display: flex;
    justify-content: space-between;
    padding: 5px 25px 0px 25px;
    max-width: 1500px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
}

.thin {
    font-weight: 500;
}

.main-container {
    background-color: #261b6b;
    height: 500px;
}

.middle-container {
    background-color: #ffffff;
    margin-top: 100px;
}

.middle-container .bullets {
    width: 900px;
    color: #3a86ff;
    margin: auto;
}

.rectangle {
    margin-top: 50px;
    display: flex;
    align-items: center;
    background-color: #f7e7e7;
}

.rectangle p {
    font-size: 20px;
}

.rectangle img {
    width: 120px;
    height: 120px;
    padding: 20px;
}

#prizes-section {
    width: 900px;
    margin: auto;
    margin-top: 100px;
}

.prize {
    font-size: 20px;
    height: 30px;
    text-align: center;
    background-color: #55ff00;
    color: rgb(24, 11, 82);
}

.portrait {
    width: 200px;
}

#boxes {
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
}

#coupons {
    width: 450px;
    background-color: #280251;
}

#lottery-section {
    height: 1000px;
}

.text-container {
    width: 700px;
}

.main-title {
    color: rgb(255, 247, 0);
    font-size: 60px;
}

.middle-title {
    font-size: 65px;
    margin: auto;
    margin-top: 200px;
    text-align: center;
}

.sub-title {
    color: #efdb04;
    font-size: 50px;
    text-align: center;
}

.inner-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

#main-portrait {
    text-align: center;
}

.main-img {
    width: 200px;
}

.legal-links {
    color: #ff006e;
}

.card a {
    color: #3a86ff;
    text-decoration: none;
}

.card .container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    max-width: 300px;
    margin: auto;
    padding-bottom: 15px;
}

.store-icon {
    width: 135px;
    height: 40px;
}

.gradient {
    background: -webkit-linear-gradient(#7a1fd5, #48027d);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.game-icon {
    width: 300px;
    border-radius: 10px;
}

.hamburger {
    display: none;
    position: relative;
}

.logo {
    width: 150px;
}

.logo:hover {
    cursor: pointer;
}

.strip-blue {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    text-align: center;
}

.title {
    max-width: 800px;
    margin: auto;
    font-size: 70px;
    padding: 50px 20px 0px 20px;
    text-align: center;
    color: #ff006e;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 60px;
    justify-content: space-around;
}

.card {
    border-color: #000000;
    border-width: 5px;
    width: 30%;
    font-size: 12 px;
    padding: 20px;
    margin-bottom: 40px;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    margin-top: 120px;
    padding: 0;
}

.card h3 {
    font-size: 30px;
    color: #a833f7;
    border-radius: 25px;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    background-color: #14061d;
    color: #ffffff;
    font-weight: bold;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.footer-container p {
    text-align: center;
}

.address {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.legal-links a {
    margin: 5px;
    color: #ff006e;
}

.legal-links a:hover {
    color: #ff97b7;
}

.legal-links {
    margin-left: 70px;
    display: flex;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 500px;
    justify-content: center;
}

.wrapper img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30vw;
}

.wrapper h3 {
    text-align: center;
    font-size: 3vw;
}

.legal {
    padding-left: 10%;
    padding-right: 10%;
}

@media (max-width: 1100px) {
    .center-image {
        margin-top: 50px;
        text-align: center;
    }
    .card {
        width: 40%;
    }
}

@media (max-width: 800px) {
    .navigation {
        display: none;
        width: 400px;
    }
    .card {
        width: 100%;
    }
    .title {
        font-size: 70px;
    }
    .footer-container {
        flex-direction: column;
    }
    .footer .legal-links {
        margin: auto;
    }
}

@media (max-width: 500px) {
    .card h3 {
        margin: 0;
    }
    .header {
        padding: 10px;
    }
    .logo {
        width: 140px;
    }
    .title {
        font-size: 40px;
    }
    .legal-links {
        margin-left: 20px;
    }
}